import $ from 'jquery';
import 'datatables.net';
import ScrollBooster from 'scrollbooster';

const scrollBoosterList = [];
let newWorker;
let language = 'en';

function initScrollBooster(index) {
  //console.log(index);
  const sb = new ScrollBooster({
    viewport: document.querySelector(`#table_wraper_0${index}`),
    content: document.querySelector(`#table${index}`),
    direction: 'horizontal',
    scrollMode: 'transform',
    bounce: false,
    textSelection: false,
    friction: 0.1
  });
  scrollBoosterList.push(sb);
}

function showUpdateBar() {
  $('.button_update').addClass('show');
}

function initDatatable2() {
  const tableCount = json.table.length;
  let tableHeaders = '';
  for (let j = 0; j < tableCount; j++) {
    // create table header
    tableHeaders = '';
    $.each(json.table[j].columns[language], function (i, val) {

      tableHeaders += `<th>${val[0]}</th>`;
      //alert( val[0] );
    });

    $(`#table_wraper_0${j}`).empty();
    $(`#table_wraper_0${j}`).append(
      `<table id="table${j}" class="table table-striped">
          <thead>
              <tr>${tableHeaders}</tr>
          </thead>
        </table>`
    );

    // Setup - add a text input to each footer cell
    let showFilter = json.table[j].show_filter;
    if (showFilter === 'true') {
      $(`#table${j} thead tr`).clone(true).addClass('filters').appendTo(`#table${j} thead`);
    }

    // fill table
    $(`#table${j}`).dataTable({
      data: json.table[j].data,
      paging: false,
      order: [],
      searching: true,
      info: false,
      orderCellsTop: true,
      fixedHeader: true,
      initComplete() {
        const api = this.api();
        api.columns().every(function (colIdx) {
          //console.log(colIdx);
          const column = this;
          const clearButton = $('<a class="clear_button" data-index="'+j+'" href="">RESET</a>');

          // clear filter
          let hasFilter = json.table[j].columns[language][colIdx][1];
          //console.log(colIdx + " / " + hasFilter);

          // add clear button
          if(colIdx === 0) {
            $(`#table${j} .filters th`).eq($(api.column(colIdx).header()).index()).empty();
            clearButton.appendTo($(`#table${j} .filters th`).eq($(api.column(colIdx).header()).index()).empty())
            return;
          }
          // hide spalten filter
          else  if(hasFilter === "false") {
            $(`#table${j} .filters th`).eq($(api.column(colIdx).header()).index()).empty();
            return;
          }

          const select = $('<select><option value=""> </option></select>');
          if (showFilter === 'true') {
            select.appendTo($(`#table${j} .filters th`).eq($(api.column(colIdx).header()).index()).empty())
              // .appendTo($(column.footer()).empty())
              .on('change', function () {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());
                //val = val.replace("\"", "'");
                column
                  .search(val ? `^${val}$` : '', true, false)
                  .draw();
              });
          }
          column
            .data()
            .unique()
            .sort()
            .each(function (d) {
              if (showFilter === 'true') {
                select.append(`<option value=${d}>${d}</option>`);
              }
            });
        });
      }
    });

    // init scroller
    initScrollBooster(j);
  }
}

function updateTextFields() {

  // version
  $('.version_text').html(json.content[0].version);
  // header
  $('.header_headline_text').html(json.content[0].header[0][language][0].headline);
  $('.header_button_label_01').html(json.content[0].header[0][language][0].button_label_01);
  $('.header_button_label_02').html(json.content[0].header[0][language][0].button_label_02);
  $('.header_button_label_03').html(json.content[0].header[0][language][0].button_label_03);
  $('.header_button_label_04').html(json.content[0].header[0][language][0].button_label_04);
  $('.header_button_label_05').html(json.content[0].header[0][language][0].button_label_05);
  $('.header_button_label_06').html(json.content[0].header[0][language][0].button_label_06);

  // section1
  $('.section_01_headline').html(json.content[0].section_01[0][language][0].section_01_headline);
  $('.section_01_image_headline').html(json.content[0].section_01[0][language][0].section_01_image_headline);

  // section2
  $('.section_02_headline').html(json.content[0].section_02[0][language][0].section_02_headline);
  $('.section_02_image_headline').html(json.content[0].section_02[0][language][0].section_02_image_headline);

  // section3
  $('.section_03_headline').html(json.content[0].section_03[0][language][0].section_03_headline);
  $('.section_03_copy').html(json.content[0].section_03[0][language][0].section_03_copy);
  $('.section_03_image_headline_01').html(json.content[0].section_03[0][language][0].section_03_image_headline_01);
  $('.section_03_image_headline_02').html(json.content[0].section_03[0][language][0].section_03_image_headline_02);

  // section4
  $('.section_04_headline').html(json.content[0].section_04[0][language][0].section_04_headline);
  $('.section_04_image_headline').html(json.content[0].section_04[0][language][0].section_04_image_headline);
  $('.section_04_sequence_headline').html(json.content[0].section_04[0][language][0].section_04_sequence_headline);
  $('.section_04_sequence_copy_01').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_01);
  $('.section_04_sequence_copy_02').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_02);
  $('.section_04_sequence_copy_03').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_03);
  $('.section_04_sequence_copy_04').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_04);
  $('.section_04_sequence_copy_05').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_05);
  $('.section_04_sequence_copy_06').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_06);
  $('.section_04_sequence_copy_07').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_07);
  $('.section_04_sequence_copy_08').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_08);
  $('.section_04_sequence_copy_09').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_09);
  $('.section_04_sequence_copy_10').html(json.content[0].section_04[0][language][0].section_04_sequence_copy_10);

  // section5
  $('.section_05_headline').html(json.content[0].section_05[0][language][0].section_05_headline);
  $('.section_05_image_headline').html(json.content[0].section_05[0][language][0].section_05_image_headline);


  // section 6
  $('.section_06_headline').html(json.content[0].section_06[0][language][0].section_06_headline);
  $('.section_06_image_headline_01').html(json.content[0].section_06[0][language][0].section_06_image_headline_01);
  $('.section_06_image_headline_02').html(json.content[0].section_06[0][language][0].section_06_image_headline_02);
  $('.section_06_image_headline_03').html(json.content[0].section_06[0][language][0].section_06_image_headline_03);


  // section7
  $('.section_07_headline').html(json.content[0].section_07[0][language][0].section_07_headline);
  $('.section_07_subline').html(json.content[0].section_07[0][language][0].section_07_subline);

  // footer
  $('.footer_copy_01').html(json.content[0].footer[0][language][0].footer_copy_01);
  $('.footer_copy_02').html(json.content[0].footer[0][language][0].footer_copy_02);
  $('.footer_copy_03').html(json.content[0].footer[0][language][0].footer_copy_03);

  // tables headlines
  $('.table_headline_00').html(json.table[0].table_headline[language][0]);
  $('.table_headline_01').html(json.table[1].table_headline[language][0]);
  $('.table_headline_02').html(json.table[2].table_headline[language][0]);
  $('.table_headline_03').html(json.table[3].table_headline[language][0]);
  $('.table_headline_04').html(json.table[4].table_headline[language][0]);
  $('.table_headline_05').html(json.table[5].table_headline[language][0]);
  $('.table_headline_06').html(json.table[6].table_headline[language][0]);
  $('.table_headline_07').html(json.table[7].table_headline[language][0]);
  $('.table_headline_09').html(json.table[9].table_headline[language][0]);
  $('.table_headline_10').html(json.table[10].table_headline[language][0]);
  $('.table_headline_11').html(json.table[11].table_headline[language][0]);
  $('.table_headline_12').html(json.table[12].table_headline[language][0]);
  $('.table_headline_13').html(json.table[13].table_headline[language][0]);
  $('.table_headline_14').html(json.table[14].table_headline[language][0]);
  $('.table_headline_15').html(json.table[15].table_headline[language][0]);
}

function updateListener() {
  $('td a').click(function (e) {
    e.preventDefault();
    $('body').addClass('overflow_hidden');
    $('.overlayer').addClass('show');
    // image
    const imageId = $(this).data('image-id');
    $('.overlayer_image').removeClass('show');
    $(`.${imageId}`).addClass('show');
  });
  $('.overlayer_close, .overlayer').click(function()  {
    $('body').removeClass('overflow_hidden');
    $('.overlayer').removeClass('show');
  });
}

class Homepage {
  init() {
    initDatatable2();
    updateTextFields();
    this.initListener();
    this.onResize();
    this.initServiceWorker();
  }

  initListener = () => {
    // table link
    updateListener();

    $('.clear_button').click(function(e) {
      e.preventDefault();
      var index = $(this).data('index');
      var table = $('#table'+index).DataTable();
      table.search('').columns().search('').draw();

      $("#table"+index+" select").val("");
    });
  };

  onResize = () => {
    $(window)
      .resize(function () {
        if (scrollBoosterList.length > 0) {
          scrollBoosterList[0].updateMetrics();
        }
      })
      .resize();
  };

  initServiceWorker = () => {
    console.log('initServiceWorker : ' + ('serviceWorker' in navigator) );
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/serviceWorker.js', { scope: '/' }) // change path for subfolder '/gore/'
        .then((reg) => {
          reg.addEventListener('updatefound', () => {
            // A wild service worker has appeared in reg.installing!
            console.log('serivce worker: registered');
            newWorker = reg.installing;

            newWorker.addEventListener('statechange', () => {
              console.log('serivce worker: statechange');
              // Has network.state changed?
              switch (newWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                    // new update available
                    showUpdateBar();
                  }
                  // No update available
                  break;
              }
            });
          });
        });

      let refreshing;
      navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
      });
    }
  };
}

export default Homepage;
