import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

let naviIsOpen = false;

function openNavigation() {
  $('#toggle').addClass('active');
  $('#mainNav').addClass('open');
  $('.logo .white').addClass('show');
  $('.logo .black').removeClass('show');

  $('#toggle').addClass('over_menu');

  naviIsOpen = true;
}

function closeNavigation() {
  $('#mainNav').addClass('closing');

  setTimeout(function () {
    $('#mainNav').removeClass('open');

    $('.logo .white').removeClass('show');
    $('.logo .black').addClass('show');
    $('#toggle').removeClass('active');

    $('#toggle').removeClass('over_menu');
    $('.header_icon').removeClass('over_menu');

    naviIsOpen = false;
  }, 500);

  setTimeout(function () {
    $('#mainNav').removeClass('closing');
  }, 1000);
}

class ModuleNavigation {
  init() {
    this.show();
    this.initListener();
    this.onScroll();
    this.initAnimation();
  }

  show = () => {
    setTimeout(function () {
      $('.navbar')
        .delay(1500)
        .queue(function () {
          $(this).addClass('show');
          $('#toggle').addClass('show');
        });
    }, 0);
  };

  initListener = () => {
    $('#toggle').click(function () {
      if (naviIsOpen === false) {
        openNavigation();
      } else {
        closeNavigation();
      }
    });

    $('#mainNav').click(function () {
      closeNavigation();
    });

    $('.menu_item').click(function () {
      closeNavigation();
    });
    $('.menu_item_scroll').click(function (e) {
      e.preventDefault();
      const aid = $(this).attr('href');
      $('html,body').animate({ scrollTop: $(aid).offset().top }, 500);
    });
  };

  onScroll = () => {
    $(window)
      .scroll(function () {
        //closeNavigation();
      })
      .scroll();
  };

  initAnimation = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.logo', {
      scrollTrigger: {
        trigger: '#header',
        start: 'top 0%',
        end: 'center 10%',
        markers: false,
        scrub: 0
      },
      y: -200
    });
  };
}

export default ModuleNavigation;
