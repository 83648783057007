// JavaScript
import 'src/scripts/main'

// Styles
import 'src/assets/styles/main.scss'

// Favicon
import 'src/assets/images/favicon.svg'

// Web App Manifest
import 'src/manifest.json'
