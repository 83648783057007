// Vendors
import 'src/scripts/vendor/jquery'
import 'src/scripts/vendor/bootstrap'
import ModulePreloader from "./modules/modulePreloader";
import ModuleNavigation from "./modules/moduleNavigation";
import Homepage from "./pages/homepage";

jQuery(document).ready(function () {
  const modulePreloader = new ModulePreloader();
  modulePreloader.init();

  const moduleNavigation = new ModuleNavigation();
  moduleNavigation.init();

  const homepage = new Homepage();
  homepage.init();
});
